<template>
    <div>
        <NewBanner class="stickyNew"/>
        <app-wrapper-alt>
            <template v-slot:child>
                <pos-open-register v-if="!activeRegister || activeRegister?.closingTime" :register="latestRegister" />
                <pos-close-register v-else :register="activeRegister" />
            </template>
        </app-wrapper-alt>
    </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import AppWrapperAlt from '@/layout/AppWrapperAlt'
import PosOpenRegister from '@/components/PosOpenRegister'
import PosCloseRegister from '@/components/PosCloseRegister'
import NewBanner from '@/components/NewBanner'

export default {
    name: 'Register',
    components: {
        AppWrapperAlt,
        PosOpenRegister,
        PosCloseRegister,
        NewBanner,
    },

    setup() {
        const store = useStore()
        const latestRegister = computed(() => store.state?.Pos?.latestRegister)
        const activeRegister = computed(() => store.state?.Pos?.activeRegister)

        onMounted(() => {
            store.dispatch('Pos/getLatestRegister')
            store.dispatch('Pos/getActiveRegister')
            store.dispatch('Pos/updateRegisterPaymentsCounted')
        })

        return {
            latestRegister,
            activeRegister,
        }
    },
}
</script>
<style>
.stickyNew {
    position: sticky;
    top: 0;
    z-index: 53;
}
.mobile-line{
    position: sticky;
    top: 68px;
    z-index: 52;
}
</style>
