<template>
    <div>
        <!--Open Register-->
        <div class="w-90 center">
            <div class="f3 b pt4">Open Register</div>
        </div>
        <!--Open Register-->

        <div class="mt4" style="background: #F6F8FE;">
            <div class="w-90 center pv3">
                <div class="font-w1" style="color: #132C8C;">Set an opening float to open the register and make a sale.</div>
            </div>
        </div>

        <!-- Open float -->
        <pos-open-float-form width="w-50-l w-90" :show-message="false" :redirect-to-sales="true" />
        <!-- Open float -->

        <!-- last closure details -->
        <div v-if="register" class="w-90 center box-border-bottom">
            <div class="f4 b pt4">Last Register Closure Details</div>
            <div class="w-40-l w-100 center pv4">
                <div class="flex justify-between pv1">
                    <div class="b">Outlet</div>
                    <div style="color:#808392; ">{{ register.outlet }}</div>
                </div>
                <div class="flex justify-between pv1">
                    <div class="b">Register</div>
                    <div style="color:#808392;">{{ register.register }}</div>
                </div>
                <div class="flex justify-between pv1">
                    <div class="b">Closure#</div>
                    <div style="color:#808392;">Main 2</div>
                </div>
                <div class="flex justify-between pv1">
                    <div class="b">Opening Time</div>
                    <div style="color:#808392;">{{ formatDate(register.openingTime, 'ddd, Do MMMM, YYYY, h:mm a') }}</div>
                </div>
                <div class="flex justify-between pv1">
                    <div class="b">Closing Time</div>
                    <div style="color:#808392;">{{ formatDate(register.closingTime, 'ddd, Do MMMM, YYYY, h:mm a') }}</div>
                </div>
            </div>
        </div>
        <!-- last closure details -->

        <!-- last payment summary -->
        <div v-if="register" class="w-90 center pt2 pb4 box-border-bottom">
            <div class="f4 b pt4">Last Register Closure Summary</div>
            <div class="flex flex-wrap justify-between pt2" style="overflow-x:auto;">
                <div class="pt2 w-30-l w-100 lh-copy mb4">
                    Balance your register by entering the amount counted from the till and other payment terminals into the empty
                    fields here.
                </div>
                <div class="w-60-l w-100 db-lx dnx">
                    <div class="flex justify-between b box-border-bottom pb3">
                        <div class="w-30">Payment Types</div>
                        <div class="w-20">Expected()</div>
                        <div class="w-20">Counted()</div>
                        <div class="w-20">Differences()</div>
                    </div>
                    <div class="flex justify-between box-border-bottom pv3 items-center">
                        <div class="w-40">
                            <span class="b"> Cash</span><br />
                            <span class="f6"> View cash payments, <br />floats and movements</span>
                        </div>
                        <div class="w-20">
                            {{
                                formatAmount(
                                    parseFloat(register?.openingFloat) + cashPaymentsReceived?.glTotalSale,
                                    $store.state.Settings?.currency
                                )
                            }}
                        </div>
                        <div class="center">
                            {{ formatAmount(cashPaymentsReceived?.counted, $store.state.Settings?.currency) }}
                        </div>
                        <div class="w-20 tc">
                            {{
                                formatAmount(
                                    cashPaymentsReceived?.counted -
                                        (parseFloat(register?.openingFloat) + cashPaymentsReceived?.glTotalSale),
                                    $store.state.Settings?.currency
                                )
                            }}
                        </div>
                    </div>
                    <div class="flex justify-between box-border-bottom pv3 items-center">
                        <div class="w-30">
                            <span class="f6"> Cash Payments Received </span>
                        </div>
                        <div class="w-20">
                            {{ formatAmount(cashPaymentsReceived?.glTotalSale, $store.state.Settings?.currency) }}
                        </div>
                        <div class="w-20 tc">-</div>
                    </div>
                    <div class="flex justify-between box-border-bottom pv3 items-center">
                        <div class="w-30">
                            <span class="f6"> Opening Float </span>
                        </div>
                        <div class="w-20">
                            {{ formatAmount(parseFloat(register?.openingFloat), $store.state.Settings?.currency) }}
                        </div>
                        <div class="w-20 tc">-</div>
                    </div>
                    <div
                        v-for="(payment, index) in bankPaymentsReceived"
                        class="flex justify-between box-border-bottom pv3 items-center"
                        :key="index"
                    >
                        <div class="w-30 b">{{ payment.glAccount }}</div>
                        <div class="w-20">{{ formatAmount(payment.glTotalSale, $store.state.Settings?.currency) }}</div>
                        <div class="w-20">{{ formatAmount(parseFloat(payment.counted), $store.state.Settings?.currency) }}</div>
                        <div class="w-20 tc">
                            {{ formatAmount(payment.counted - payment.glTotalSale, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                    <!-- <div class="flex justify-between box-border-bottom pv3 items-center">
                        <div class="w-30 b">Store Credit</div>
                        <div class="w-20">0.00</div>
                        <div class="w-20">0.00</div>
                        <div class="w-20 tc">0.00</div>
                    </div> -->
                    <div class="flex justify-between box-border-bottom pv3 items-center">
                        <div class="w-30 b">Totals</div>
                        <div class="w-20">{{ formatAmount(latestRegisterTotals, $store.state.Settings?.currency) }}</div>
                        <div class="w-20">{{ formatAmount(latestPaymentsCounted, $store.state.Settings?.currency) }}</div>
                        <div class="w-20 tc">
                            {{ formatAmount(latestPaymentsCounted - latestRegisterTotals, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="register" class="w-90 center">
            <button class="mv4">Print Last Summary</button>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { formatDate, formatAmount } from '@/utils/lib'
import PosOpenFloatForm from './PosOpenFloatForm'

export default {
    name: 'PosOpenRegister',
    props: {
        register: Object
    },
    components: {
        PosOpenFloatForm
    },

    setup() {
        const store = useStore()
        const cashPaymentsReceived = computed(() => store.state?.Pos?.cashPaymentsReceived)
        const bankPaymentsReceived = computed(() => store.state?.Pos?.bankPaymentsReceived)
        const latestRegisterTotals = computed(() => store.state?.Pos?.latestRegisterTotals)
        const latestPaymentsCounted = computed(() => store.state?.Pos?.latestPaymentsCounted)
        return {
            formatDate,
            formatAmount,
            cashPaymentsReceived,
            bankPaymentsReceived,
            latestRegisterTotals,
            latestPaymentsCounted
        }
    }
}
</script>
<style scoped></style>
