<template>
    <div>
        <div class="w-90 center f4 b mt4">Open Float</div>
        <div class="box-border-bottom w-90 center">
            <div :class="[width, 'center pv4']">
                <div v-if="showMessage" class="alert alert-info">
                    <div><strong>No open register!</strong></div>
                    <small>Set an opening float to open the register and make a sale.</small>
                </div>
                <form @submit.prevent="createRegister">
                    <div class=" flex flex-column pb4">
                        <label class="pb2" for="">Opening Float</label>
                        <currency-input
                            class="pa2 box-border"
                            :placeholder="formatAmount(0.0, $store.state.Settings?.currency)"
                            v-model="state.data.openingFloat"
                            :options="numberFormat"
                        />
                    </div>
                    <div class="w-100">
                        <div class="b pb2">Note</div>
                        <textarea
                            class="w-100 pa2 box-border"
                            placeholder="Enter a note"
                            v-model="state.data.note"
                            maxlength="255"
                        ></textarea>
                    </div>
                    <div class="pb4">Max characters: 255</div>
                    <button class="w-100" :disabled="state.isProcessing">Open Register</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { formatAmount, formatDate } from '@/utils/lib'
import CurrencyInput from './CurrencyInput.vue'

export default {
    name: 'PosOpenRegister',
    components: { CurrencyInput },
    props: {
        width: String,
        showMessage: Boolean,
        redirectToSales: Boolean
    },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true
            }
        }
    },

    setup(props, { emit }) {
        const store = useStore()
        const router = useRouter()

        const initialState = () => ({
            data: {
                openingFloat: '',
                note: '',
                outlet: 'Main Outlet',
                register: 'Main Register',
                openingTime: ''
            },
            isProcessing: false
        })

        let state = reactive(initialState())

        const createRegister = () => {
            state.data.openingTime = new Date() // formatDateFilter(Date.now())
            state.isProcessing = true
            store
                .dispatch('Pos/createRegister', state.data)
                .then(response => {
                    if (response?.success) {
                        state.data = initialState().data
                    }
                    state.isProcessing = false

                    if (props.redirectToSales) {
                        router.push({ name: 'Pos' })
                    }
                })
                .catch(error => {
                    state.isProcessing = false
                    console.error(error)
                })
        }

        return {
            state,
            createRegister,
            formatAmount,
            formatDate
        }
    }
}
</script>
