<template>
    <div>
        <!--Open Register-->
        <div class="w-90 center">
            <div class="f3 b pt4">Close Register</div>
        </div>
        <!--Open Register-->

        <div class="mt4" style="background: #F6F8FE;">
            <div class="w-90 center pv3">
                <div class="font-w1" style="color: #132C8C;">Close your register to finalize payments and sales for the day.</div>
            </div>
        </div>

        <!-- Register details -->
        <div v-if="register" class="w-90 center box-border-bottom">
            <div class="f4 b pt4">Last Register Closure Details</div>
            <div class="w-40-l w-100 center pv4">
                <div class="flex justify-between pv1">
                    <div class="b">Outlet</div>
                    <div style="color:#808392;">{{ register?.outlet }}</div>
                </div>
                <div class="flex justify-between pv1">
                    <div class="b">Register</div>
                    <div style="color:#808392;">{{ register?.register }}</div>
                </div>
                <div class="flex justify-between pv1">
                    <div class="b">Closure#</div>
                    <div style="color:#808392;">Main 2</div>
                </div>
                <div class="flex justify-between pv1">
                    <div class="b">Opening Time</div>
                    <div style="color:#808392;">{{ formatDate(register?.openingTime, 'ddd, Do MMMM, YYYY, h:mm a') }}</div>
                </div>
            </div>
        </div>
        <!-- Register details -->

        <!-- last payment summary -->
        <div v-if="register" class="w-90 center pt2 pb4 box-border-bottom">
            <div class="f4 b pt4">Last Register Closure Summary</div>
            <div class="flex flex-wrap justify-between pt2" style="overflow-x:auto;">
                <div class="pt2 w-30-l w-100 lh-copy mb4">
                    Balance your register by entering the amount counted from the till and other payment terminals into the empty
                    fields here.
                </div>
                <div class="w-60-l w-100 db-lx dnx">
                    <div class="flex justify-between  items-center b box-border-bottom pb3">
                        <div class="w-30">Payment Types</div>
                        <div class="w-20">Expected()</div>
                        <div class="w-20">Counted()</div>
                        <div class="w-20">Differences()</div>
                    </div>
                    <div class="flex justify-between box-border-bottom pv3 items-center">
                        <div class="w-40">
                            <span class="b"> Cash </span><br />
                            <span class="f6"> View cash payments, <br />floats and movements</span>
                        </div>
                        <div class="w-20">
                            {{
                                formatAmount(
                                    parseFloat(register?.openingFloat) + (activeCashPaymentsReceived?.glTotalSale || 0),
                                    $store.state.Settings?.currency
                                )
                            }}
                        </div>
                        <div class="center">
                            <currency-input
                                class="w-50 pa2 f6 w-100"
                                v-model="activeCashPaymentsReceived.counted"
                                :options="numberFormat"
                            />
                        </div>
                        <div class="w-20 tc">{{ formatAmount(state.activeCashDifference, $store.state.Settings?.currency) }}</div>
                    </div>
                    <div class="flex justify-between box-border-bottom pv3 items-center">
                        <div class="w-30">
                            <span class="f6"> Cash Payments Received </span>
                        </div>
                        <div class="w-20">
                            {{ formatAmount(activeCashPaymentsReceived?.glTotalSale, $store.state.Settings?.currency) }}
                        </div>
                        <div class="w-20 tc">-</div>
                    </div>
                    <div class="flex justify-between box-border-bottom pv3 items-center">
                        <div class="w-30">
                            <span class="f6"> Opening Float </span>
                        </div>
                        <div class="w-20">
                            {{ formatAmount(parseFloat(register?.openingFloat), $store.state.Settings?.currency) }}
                        </div>
                        <div class="w-20 tc">-</div>
                    </div>
                    <div
                        v-for="(payment, index) in activeBankPaymentsReceived"
                        class="flex justify-between box-border-bottom pv3 items-center"
                        :key="index"
                    >
                        <div class="w-30 b">{{ payment.glAccount }}</div>
                        <div class="w-20">{{ formatAmount(payment.glTotalSale, $store.state.Settings?.currency) }}</div>
                        <div class="w-20">
                            <currency-input
                                class="w-50 pa2 f6 w-100"
                                v-model="payment.counted"
                                :options="numberFormat"
                                @input="bankCountedChanged"
                            />
                        </div>
                        <div class="w-20 tc">
                            {{ formatAmount(payment.counted - payment.glTotalSale, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                    <!-- <div class="flex justify-between box-border-bottom pv3 items-center">
                        <div class="w-30 b">Store Credit</div>
                        <div class="w-20">0.00</div>
                        <div class="w-20">0.00</div>
                        <div class="w-20 tc">0.00</div>
                    </div> -->
                    <div class="flex justify-between box-border-bottom pv3 items-center">
                        <div class="w-30 b">Totals</div>
                        <div class="w-20">{{ formatAmount(activeRegisterTotals, $store.state.Settings?.currency) }}</div>
                        <div class="w-20">{{ formatAmount(activePaymentsCounted, $store.state.Settings?.currency) }}</div>
                        <div class="w-20 tc">
                            {{ formatAmount(activePaymentsCounted - activeRegisterTotals, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="register" class="w-90 center">
            <div class="flex flex-wrap justify-between">
                <div class="w-30-l w-100"></div>
                <div class=" w-70-l w-100">
                    <form @submit.prevent="closeRegister">
                        <div class="b pb2 mt4">Note</div>
                        <div class="flex flex-column">
                            <textarea
                                v-model="state.data.closingNote"
                                class="pa2 box-border"
                                style="height: 50px;"
                                placeholder="Type to add a register closure note"
                            ></textarea>
                            <button class="mt3 mb4" :disabled="state.isProcessing">Close Register</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import Swal from 'sweetalert2'
import { formatAmount, formatDate } from '@/utils/lib'
import CurrencyInput from './CurrencyInput.vue'

export default {
    name: 'PosCloseRegister',
    components: { CurrencyInput },
    props: {
        register: Object
    },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true
            }
        }
    },

    setup(props) {
        const store = useStore()
        const activeCashPaymentsReceived = computed(() => store.state?.Pos?.activeCashPaymentsReceived)
        const activeBankPaymentsReceived = computed(() => store.state?.Pos?.activeBankPaymentsReceived)
        const activeRegisterTotals = computed(() => store.state?.Pos?.activeRegisterTotals)
        const activePaymentsCounted = computed(() => store.state?.Pos?.activePaymentsCounted)

        const initialState = () => ({
            data: {
                closingNote: '',
                closingTime: '',
                paymentSummary: []
            },
            activeCashPaymentsReceived: {
                glAccount: '',
                glTotalSale: '',
                counted: ''
            },
            activeBankPaymentsReceived: [],
            activeCashDifference: 0,
            isProcessing: false
        })

        let state = reactive(initialState())

        const updatePaymentSummary = () => {
            state.activeCashPaymentsReceived = activeCashPaymentsReceived.value
            state.activeBankPaymentsReceived = activeBankPaymentsReceived.value
            store.dispatch('Pos/updateRegisterPaymentsCounted')
        }

        watch(
            () => activeCashPaymentsReceived.value?.counted,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    state.activeCashPaymentsReceived = activeCashPaymentsReceived.value
                    state.activeBankPaymentsReceived = activeBankPaymentsReceived.value
                    state.activeCashDifference =
                        newVal - (parseFloat(props.register.openingFloat) + activeCashPaymentsReceived.value.glTotalSale)

                    store.dispatch('Pos/updateRegisterPaymentsCounted')
                }
                store.dispatch('Pos/updateRegisterPaymentsCounted')
                // console.log('WATCH FIRED')
            }
        )

        const bankCountedChanged = () => {
            store.dispatch('Pos/updateRegisterPaymentsCounted')
        }

        const closeRegister = () => {
            updatePaymentSummary()
            Swal.fire({
                title: `Are you sure you want to close this register?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Continue!',
                denyButtonText: `No, Cancel!`
            }).then(result => {
                if (result.isConfirmed) {
                    state.data.closingTime = new Date()
                    state.isProcessing = true
                    state.activeBankPaymentsReceived.push({
                        glAccount: state.activeCashPaymentsReceived.glAccount,
                        glTotalSale: state.activeCashPaymentsReceived.glTotalSale,
                        counted: state.activeCashPaymentsReceived.counted
                    })

                    state.data.paymentSummary = state.activeBankPaymentsReceived
                    const data = { id: props.register._id, ...state.data }
                    store
                        .dispatch('Pos/closeRegister', data)
                        .then(response => {
                            if (response?.success) {
                                state.data = initialState().data
                                store.dispatch('Pos/getLatestRegister')
                                store.dispatch('Pos/getActiveRegister')
                                store.dispatch('Pos/updateRegisterPaymentsCounted')
                            }
                            state.isProcessing = false
                        })
                        .catch(error => {
                            state.isProcessing = false
                            console.error(error)
                        })
                }
            })
        }

        return {
            state,
            closeRegister,
            formatDate,
            formatAmount,
            activeCashPaymentsReceived,
            activeBankPaymentsReceived,
            activeRegisterTotals,
            activePaymentsCounted,
            bankCountedChanged
        }
    }
}
</script>
<style scoped></style>
